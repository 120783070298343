import { ReactElement } from 'react';

export default function PatButton({
  children,
  action,
}: {
  children: ReactElement | string;
  action: Function;
}) {
  return (
    <div
      onClick={async () => {
        await action();
      }}
      className={
        'bg-[#9023FF] rounded-full text-center px-2 py-3 cursor-pointer m-4 text-white font-bold'
      }
    >
      {children}
    </div>
  );
}
