import ProfileLayout from '@/layouts/ProfileLayout';
import Head from 'next/head';
import PatButton from '@/components/patsystem/PatButton';
import { useRouter } from 'next/router';

export default function NotFoundPage() {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>Not Found — Vouch</title>
      </Head>
      <div
        className={
          'absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center justify-center text-white poppins bg-black'
        }
      >
        <div className={'text-[36px] font-bold -mt-[75px]'}>
          Whoops, we can’t find that page.
        </div>
        <div>
          <PatButton action={async () => await router.push('/')}>
            <div className={'inline-block w-[150px]'}>Back home</div>
          </PatButton>
        </div>
        <div className={'mt-4 text-[14px] font-light'}>
          Or{' '}
          <span className={'font-bold'}>
            <a href={'mailto:help@vouch.app'}>
              complain to our social media manager
            </a>
          </span>
        </div>
        <div className={'mt-6'}>
          <img src={'https://files.vouch.app/404.png'} alt={'404'} />
        </div>
      </div>
    </>
  );
}
